<template>
  <silo-property-form :edit="false" :hide-dialog-button="true" />
</template>

<script>
import SiloPropertyForm from "./SiloPropertyForm.vue";

export default {
  name: "SiloPropertyCreate",

  components: {
    SiloPropertyForm,
  },
};
</script>